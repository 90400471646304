



















































































import { defineComponent, ref, computed, ComputedRef, Ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';
import { useUser, User } from '@/admin/user';
import { useNotification } from '@/composition/notification';
import { useFavorites, useSettingFavorite } from '@/composition/favorites';
import { downloadCsv } from '@/admin/util';
import { getOptionsTitle } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcTableHeaderFilter from '@/components/FcTableHeaderFilter.vue';
import FcCheckFilter from '@/components/FcCheckFilter.vue';
import core from '@/admin/core';

export default defineComponent({
  name: 'FavoriteUsers',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    FcTableHeaderFilter,
    FcCheckFilter,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('favorites'));

    const { userProperties, getUsers } = useUser();
    const { favorites } = useFavorites();
    const setting = useSettingFavorite();
    const notification = useNotification();
    const isLoading = ref(true);
    const isDisplayDeletedItem = ref(false);
    // ユーザー一覧
    const headers: ComputedRef<DataTableHeader[]> = computed(() => {
      const properties = [
        { text: 'ステータス', value: 'status' },
        { text: 'ユーザーID', value: 'userId' },
        { text: '会員番号', value: 'userNumber' },
        { text: '推し', value: 'favoriteItemName' },
        { text: '推し歴', value: 'favoritePeriod' },
        { text: '推し開始日', value: 'favoriteStartDate' },
        { text: 'ランク', value: 'favoriteItemStatusName' },
        { text: 'メールアドレス', value: 'email' },
        ...userProperties.value.map(({ key, title }) => ({ text: title, value: `properties.${key}` })),
        { text: '会員番号', value: 'userNumber' },
        { text: '登録日', value: 'createDate' },
      ];
      return properties;
    });
    const displayHeaders: Ref<DataTableHeader[]> = ref([]);
    const users: Ref<User[]> = ref([]);

    // フリーワード検索
    const searchWord = ref('');
    const selectedFavoriteNames: Ref<string[]> = ref([]);
    const selectedFavoriteStatus: Ref<string[]> = ref([]);
    // 表示ユーザー一覧
    const displayUsers: ComputedRef<User[]> = computed(() => {
      return (
        users.value
          // 削除済みユーザーを表示するか
          .filter((user) => isDisplayDeletedItem.value || !user.deleteDate)
          // 推しメンによる絞り込み
          .filter(
            (item) => !selectedFavoriteNames.value.length || selectedFavoriteNames.value.includes(item.favoriteItemId)
          )
          // ランクによる絞り込み
          .filter(
            (item) =>
              !selectedFavoriteStatus.value.length ||
              selectedFavoriteStatus.value.length === setting.itemOptions.length ||
              selectedFavoriteStatus.value.includes(item.favoriteItemStatusId)
          )
          // フリーワードによる絞り込み
          .filter((user) => !searchWord.value || JSON.stringify(user).includes(searchWord.value))
      );
    });

    getUsers()
      .then((result) => (users.value = result))
      .catch((error) => notification.error(error))
      .finally(() => (isLoading.value = false));
    // テーブル高さ
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    // ダウンロードボタン
    const download = () => {
      // actionsの列は削除
      const csvHeaders = displayHeaders.value.slice(0, -1);
      const csvItems = displayUsers.value.map((item) => ({
        ...item,
        status: !item.isExpiredFavoriteItem ? 'アクティブ' : '有効期限切れ',
        favoriteItemName: getOptionsTitle(favorites.favoriteNames, item.favoriteItemId),
      }));
      downloadCsv(csvHeaders, csvItems, 'favorite.csv');
    };

    return {
      pageTitle: '推しメン設定状況',
      myRoleSettings,
      favorites,
      setting,
      getOptionsTitle,
      isLoading,
      isDisplayDeletedItem,
      displayUsers,
      headers,
      tableHeight,
      open,
      selectedFavoriteNames,
      selectedFavoriteStatus,
      download,
      displayHeaders,
      isPermitted: core.isPermitted,
    };
  },
});
